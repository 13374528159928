const BASE_URL = "https://trade.affyclouditsolutions.com/api";
export const VERIFY_REGISTRATION_LINK = BASE_URL + "/verify-registration-link";
export const CUSTOMER_REGISTRATION = BASE_URL + "/customer-registration";

// Customer
export const CUSTOMER_INFO = BASE_URL + "/customer-info";
export const CUSTOMER_LOGIN = BASE_URL + "/customer/login";
export const CUSTOMER_TRADES_HISTORY = BASE_URL + "/order-history/"
export const CUSTOMER_LOGOUT = BASE_URL + "/customer/permanent/logout"
export const VERIFY_SHARE_LINK = BASE_URL + "/subadmin/verify-link"
export const CUSTOMER_LEAD_SAVE = BASE_URL + "/subadmin/customer-leads"
