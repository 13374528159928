import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { VERIFY_SHARE_LINK, CUSTOMER_LEAD_SAVE } from "../Components/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  enforceMaxLength,
  enforceNoSpecialChars,
  enforceNoSpaces,
  enforceTextAndSpacesOnly,
} from "../Components/utility";

function LeadForm() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  console.log(token);
  const [isVerified, setIsVerified] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await fetch(VERIFY_SHARE_LINK, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ registration_link: token }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data.error);
          if (data.error == "false") {
            console.log(1);
            setIsVerified(true);
            setFormVisible(true);
          } else {
            toast.error("Invalid or expired link.");
          }
        } else {
          toast.error("Failed to verify the link.");
        }
      } catch (error) {
        console.error("Error verifying link:", error.message);
        toast.error("An error occurred during verification.");
      }
    };

    if (token) {
      verifyToken();
    }
  }, [token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    try {
      const response = await fetch(CUSTOMER_LEAD_SAVE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: formData.get("name"),
          email: formData.get("email"),
          mobile: formData.get("mobile"),
          city: formData.get("city"),
          token: token,
        }),
      });

      if (response.ok) {
        toast.success("Form submitted successfully.");
      } else {
        toast.error("Failed to submit the form.");
        setMessage(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  if (!isVerified) {
    return (
      <div className="container mt-5 text-center">
        Link Expired. Contact Admin
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <h3 className="text-center">Contact Form</h3>
      {formVisible ? (
        <form className="mt-4" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Enter your name"
              onInput={enforceTextAndSpacesOnly}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Enter your email"
              onInput={enforceNoSpaces}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile Number</label>
            <input
              type="tel"
              className="form-control"
              id="mobile"
              name="mobile"
              placeholder="Enter your mobile number"
              onInput={enforceMaxLength}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="city">City</label>
            <input
              type="text"
              className="form-control"
              id="city"
              name="city"
              placeholder="Enter your city"
              onInput={enforceNoSpecialChars}
              required
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block theme-color-bg"
          >
            Submit
          </button>
        </form>
      ) : (
        <p className="text-center">Loading form...</p>
      )}
      <ToastContainer />
    </div>
  );
}

export default LeadForm;
