import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import CustomerForm from "./Pages/CustomerForm";
import CustomerLogin from "./Pages/CustomerLogin";
import Dashboard from "./Pages/Dashboard";
import LeadForm from "./Pages/LeadForm";
import './App.css'

const ProtectedRoute = ({ element, ...rest }) => {
  const customer_id = localStorage.getItem("customer_id");
  const tradeDate = localStorage.getItem("tradeDate");

  if (!customer_id || !tradeDate) {
    return <Navigate to="/login" />;
  }
  
  return element;
};

const RedirectHandler = () => {
  const customer_id = localStorage.getItem("customer_id");
  const tradeDate = localStorage.getItem("tradeDate");

  if (customer_id && tradeDate) {
    return <Navigate to="/dashboard" />;
  } else {
    return <Navigate to="/login" />;
  }
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/customer-register" element={<CustomerForm />} />
        <Route path="/contact-form" element={<LeadForm />} />
        <Route path="/login" element={<CustomerLogin />} />

        {/* Redirect Handler for automatic redirection based on localStorage */}
        <Route path="*" element={<RedirectHandler />} />

        {/* Protected Route for Dashboard */}
        <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
      </Routes>
    </Router>
  );
}

export default App;
