import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_INFO, CUSTOMER_LOGIN } from "../Components/api";
import { enforceNoSpaces } from "../Components/utility";

export default function CustomerLogin() {
  const [mobile, setMobile] = useState("");
  const [customerPlatformType, setCustomerPlatformType] = useState("");
  const [customerData, setCustomerData] = useState(null);
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [totp, setTotp] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      const savedMobile = localStorage.getItem("mobile");
      const savedPlatformType = localStorage.getItem("customerPlatformType");
      let upstoxCustomerID = localStorage.getItem("upstoxCustomerID");
      upstoxCustomerID = upstoxCustomerID.replace(/-/g, "");

      if (savedMobile && savedPlatformType) {
        handleUpstoxLogin(upstoxCustomerID, savedPlatformType, code);
      }
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(CUSTOMER_INFO, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile: mobile,
          customer_platformType: customerPlatformType,
        }),
      });

      if (!response.ok) {
        if (response.status === 404) {
          toast.error("User not found");
        } else {
          throw new Error("Network response was not ok");
        }
        return;
      }

      const data = await response.json();
      console.log(data);
      setCustomerData(data);
      localStorage.setItem("customerId", data.customer_id);
      setError("");
    } catch (err) {
      console.error("Error fetching customer data:", err);
      setCustomerData(null);
      setError("Error fetching customer data.");
    }
  };

  const handleLogin = async () => {
    if (customerData.customer_platformType === "AngelOne") {
      try {
        const response = await fetch(CUSTOMER_LOGIN, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customer_id: customerData.customer_id,
            customer_platformType: customerData.customer_platformType,
            password: password,
            totp: totp,
          }),
        });
        console.log(response);
        if (!response.ok) {
          throw new Error("Login failed");
        }

        const data = await response.json();
        console.log(data.login);
        // local storage
        localStorage.setItem("feedToken", data.data.feedToken);
        localStorage.setItem("jwtToken", data.data.jwtToken);
        localStorage.setItem("refreshToken", data.data.refreshToken);
        localStorage.setItem("customer_id", data.customer_id);
        const today = new Date().toISOString().split("T")[0];
        localStorage.setItem("tradeDate", today);
        if (data.login) {
          window.location.href = "/dashboard";
        }
      } catch (err) {
        console.error("Error logging in:", err);
        toast.error("Login failed");
      }
    } else if (customerData.customer_platformType === "Upstox") {
      // Save mobile and platform type to localStorage
      localStorage.setItem("mobile", mobile);
      localStorage.setItem("customerPlatformType", customerPlatformType);
      localStorage.setItem("upstoxCustomerID", customerData.customer_id);
      // Redirect to Upstox login
      const clientId = customerData.upstox_client_id;
      const redirectUri = encodeURIComponent("https://trade.affyclouditsolutions.com/login");
      const upstoxUrl = `https://api.upstox.com/v2/login/authorization/dialog?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code`;

      window.location.href = upstoxUrl;
    }
  };

  const handleUpstoxLogin = async (upstoxCustomerID, platformType, code) => {
    console.log(upstoxCustomerID, platformType, code);
    try {
      const formData = `customer_id=${encodeURIComponent(
        upstoxCustomerID
      )}&customer_platformType=${encodeURIComponent(
        platformType
      )}&code=${encodeURIComponent(code)}`;

      const response = await fetch(CUSTOMER_LOGIN, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });

      console.log(response);

      if (response.status === 401) {
        throw new Error(
          "Unauthorized: Please check your credentials and try again."
        );
      }

      if (!response.ok) {
        throw new Error("Login failed");
      }

      const data = await response.json();
      console.log("Upstox login successful:", data);
      localStorage.setItem("customer_id", data.customer_id);
      const today = new Date().toISOString().split("T")[0];
      localStorage.setItem("tradeDate", today);
      if (data.login) {
        window.location.href = "/dashboard";
      }
    } catch (err) {
      console.error("Error logging in:", err);
      toast.error("Upstox login failed");
    }
  };

  return (
    <div className="bg-login" id="wrapper">
      <div className="card card-authentication1 mx-auto">
        <div className="card-body">
          <div className="card-content p-2">
            <div className="text-center">
              {/* <img className="img-logo" src="https://affyclouditsolutions.com/assets/images/favicon.png" alt="logo icon" /> */}
              <img
                className="img-logo"
                src="https://affyclouditsolutions.com/static/media/affy_logo.7354960bda008279c013.png"
                alt="logo icon"
              />
            </div>
            <div className="card-title text-uppercase text-center py-3 theme-color-text">
              Sign In
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                {!customerData && (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="mobile" className="sr-only">
                        Mobile Number
                      </label>
                      <div className="position-relative has-icon-right">
                        <input
                          type="number"
                          id="mobile"
                          name="mobile"
                          className="form-control input-shadow"
                          placeholder="Enter Registered Mobile"
                          maxLength={10}
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                        <div className="form-control-position">
                          <i className="icon-user" />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="customerPlatformType" className="sr-only">
                        Platform
                      </label>
                      <div className="position-relative has-icon-right">
                        <select
                          className="form-control input-shadow"
                          id="customerPlatformType"
                          name="customerPlatformType"
                          value={customerPlatformType}
                          onChange={(e) =>
                            setCustomerPlatformType(e.target.value)
                          }
                        >
                          <option value="" disabled>
                            Select Platform
                          </option>
                          <option value="AngelOne">AngelOne</option>
                          <option value="Upstox">Upstox</option>
                        </select>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-success btn-block theme-color-bg"
                    >
                      Sign In
                    </button>
                  </form>
                )}
                {customerData && (
                  <div className="mt-3 after-verfiy-container">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="text-center">
                          <strong> {customerData.customer_name}</strong>
                        </h5>

                        {/* <p>
                          <strong>Platform:</strong>
                          {customerData.customer_platformType}
                        </p> */}
                        {customerData.customer_platformType === "AngelOne" && (
                          <>
                            <div className="platform-logo-container">
                              <img
                                className="text-center my-1 platform-logo"
                                src="https://cdn.angelone.in/sparkweb/images/angel-one-logo.png"
                                alt={customerData.customer_platformType}
                              />
                            </div>
                            {/* <p>
                              <strong>API Key:</strong>
                              {customerData.angel_api_key}
                            </p>
                            <p>
                              <strong>Client Code:</strong>
                              {customerData.angel_client_code}
                            </p> */}
                            <div className="form-group">
                              <label htmlFor="password" className="sr-only">
                                Password
                              </label>
                              <input
                                type="password"
                                id="password"
                                name="password"
                                className="form-control input-shadow"
                                placeholder="Password"
                                value={password}
                                onInput={enforceNoSpaces}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="totp" className="sr-only">
                                TOTP
                              </label>
                              <input
                                type="text"
                                id="totp"
                                name="totp"
                                className="form-control input-shadow"
                                placeholder="TOTP"
                                value={totp}
                                onInput={enforceNoSpaces}
                                onChange={(e) => setTotp(e.target.value)}
                              />
                            </div>
                            <button
                              className="btn btn-success btn-block mt-3 theme-color-bg"
                              onClick={handleLogin}
                            >
                              Login with this account
                            </button>
                          </>
                        )}
                        {customerData.customer_platformType === "Upstox" && (
                          <>
                            <div  className="platform-logo-container">
                              <img
                                className="text-center my-1 platform-logo"
                                src="https://upstox.com/app/themes/upstox/dist/img/logo/desktop-logo.svg"
                                alt={customerData.customer_platformType}
                              />
                            </div>
                            {/* <p>
                              <strong>Client Code:</strong>
                              {customerData.upstox_client_id}
                            </p> */}
                            <button
                              className="btn btn-success btn-block mt-3 theme-color-bg"
                              onClick={handleLogin}
                            >
                              Login with this account
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {error && <div className="mt-3 text-danger">{error}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
