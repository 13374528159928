import React, { useState, useEffect } from "react";
import { CUSTOMER_TRADES_HISTORY } from "../Components/api";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TextField,
  TablePagination,
} from "@mui/material";

import Navbar from "../Components/Navbar";

export default function Dashboard() {
  const [tradeReports, setTradeReports] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("time_stamp");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  useEffect(() => {
    const fetchOrderHistory = async () => {
      // const customerId = localStorage.getItem("customer_id");
      // console.log("Customer ID:", customerId);

      // if (!customerId) {
      //   console.error("No customer ID found in localStorage");
      //   return;
      // }
      const customerId = localStorage.getItem("customer_id");
      const sanitizedCustomerId = customerId.replace(/-/g, '');
      if (customerId) {
         
          console.log("Customer ID:", sanitizedCustomerId);
      } else {
          console.log("Customer ID not found in local storage.");
      }
      
      try {
        const response = await fetch(`${CUSTOMER_TRADES_HISTORY}${sanitizedCustomerId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Fetched Data:", data); // Debugging line

        if (data && Array.isArray(data)) {
          setTradeReports(data);
        } else {
          console.error("Order history is not an array", data);
        }
      } catch (error) {
        console.error("Error fetching order history:", error);
      }
    };

    fetchOrderHistory();
  }, []);

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const filteredData = tradeReports.filter((report) =>
    Object.values(report).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const sortedData = filteredData.sort((a, b) => {
    if (orderBy === "time_stamp") {
      return order === "asc"
        ? new Date(a[orderBy]) - new Date(b[orderBy])
        : new Date(b[orderBy]) - new Date(a[orderBy]);
    } else {
      return order === "asc"
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
    }
  });

  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <Navbar />
      <div className="content-wrapper container-fluid mb-0">
        <h4 className="my-4 text-center">Trade History</h4>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {tradeReports.length > 0 ? (
          <div className="table-reports">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>S No.</TableCell>
                    {/* <TableCell>Customer</TableCell> */}
                    {/* <TableCell>Group</TableCell> */}
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "time_stamp"}
                        direction={orderBy === "time_stamp" ? order : "asc"}
                        onClick={() => handleSortRequest("time_stamp")}
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "tradingsymbol"}
                        direction={orderBy === "tradingsymbol" ? order : "asc"}
                        onClick={() => handleSortRequest("tradingsymbol")}
                      >
                        Symbol
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Platform</TableCell>
                    <TableCell>Exchange</TableCell>
                    <TableCell>Product Type</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "quantity"}
                        direction={orderBy === "quantity" ? order : "asc"}
                        onClick={() => handleSortRequest("quantity")}
                      >
                        Quantity
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Variety</TableCell>
                    <TableCell>Transaction</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Reason</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell
                        className={
                          item.transactiontype === "SELL" ? "bg-sell" : "bg-buy"
                        }
                      >
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      {/* <TableCell>{item.customer_name}</TableCell> */}
                      {/* <TableCell>{item.group_name}</TableCell> */}
                      <TableCell>
                        {new Date(item.time_stamp).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: false,
                        })}
                      </TableCell>
                      <TableCell>{item.tradingsymbol}</TableCell>
                      <TableCell
                        className={
                          item.platform === "angel"
                            ? "platform-angel"
                            : item.platform === "upstox"
                            ? "platform-upstox"
                            : ""
                        }
                      >
                        {item.platform}
                      </TableCell>
                      <TableCell>{item.exchange}</TableCell>
                      <TableCell>
                        {item.producttype === "I"
                          ? "INTRADAY"
                          : item.producttype === "D"
                          ? "DELIVERY"
                          : item.producttype}
                      </TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>{item.variety ? item.variety : "-"}</TableCell>
                      <TableCell
                        className={
                          item.transactiontype === "SELL" ? "bg-sell" : "bg-buy"
                        }
                      >
                        {item.transactiontype}
                      </TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell>{item.text}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={sortedData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </div>
        ) : (
          "No Trade Found"
        )}
      </div>
    </>
  );
}
