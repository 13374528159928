// // import React, { useState, useEffect } from "react";
// // import { useParams, useLocation } from "react-router-dom";

// // export default function CustomerForm() {
// //     const { id } = useParams();
// //     const location = useLocation();
// //     const [formData, setFormData] = useState({
// //       customer_name: "",
// //       mobile: "",
// //       customer_email: "",
// //       customer_platformType: "",
// //       client_code: "",
// //       api_key: "",
// //       registration_link: "",
// //     });
// //     const [loading, setLoading] = useState(true);

// //     useEffect(() => {
// //       const searchParams = new URLSearchParams(location.search);
// //       const token = searchParams.get("token");
// //       console.log(token);
// //       const fetchRegistrationLink = async () => { // Remove token argument from here
// //         if (token) {
// //           try {
// //             const response = await fetch(
// //               `http://localhost:8000/api/verify-registration-link`,
// //               {
// //                 method: "POST",
// //                 headers: {
// //                   "Content-Type": "application/json",
// //                 },
// //                 body: JSON.stringify({ registration_link: token }), // Send token in request body
// //               }
// //             );
// //             const result = await response.json();
// //             console.log(result); // Log the response
// //             setFormData((prevData) => ({
// //               ...prevData,
// //               registration_link: result.registration_link,
// //             }));
// //             setLoading(false);
// //           } catch (error) {
// //             console.error("Error fetching registration link:", error);
// //             setLoading(false);
// //           }
// //         } else {
// //           console.error("Token not found in URL");
// //           setLoading(false);
// //         }
// //       };

// //       fetchRegistrationLink(); // Remove token argument from here
// //     }, [location.search]);

// //     const handleChange = (e) => {
// //       const { name, value } = e.target;
// //       setFormData({
// //         ...formData,
// //         [name]: value,
// //       });
// //     };

// //     const handleSubmit = async (e) => {
// //       e.preventDefault();
// //       try {
// //         const response = await fetch(
// //           `http://127.0.0.1:8000/api/customer-registration/${id}`,
// //           {
// //             method: "POST",
// //             headers: {
// //               "Content-Type": "application/json",
// //             },
// //             body: JSON.stringify(formData),
// //           }
// //         );
// //         console.log(formData);
// //         if (response.ok) {
// //           const result = await response.json();
// //           console.log("Success:", result);
// //         } else {
// //           console.error("Error:", response.statusText);
// //         }
// //       } catch (error) {
// //         console.error("Error:", error);
// //       }
// //     };

// //     if (loading) {
// //       return <div className="loader">Loading...</div>; // Display a loader while fetching data
// //     }

// //   return (
// //     <div className="container">
// //       <form onSubmit={handleSubmit}>
// //         <h2 className="text-center my-3">Registration Form</h2>
// //         <div className="row">
// //           <div className="col-md-6 my-2">
// //             <div className="form-group">
// //               <label htmlFor="customer_name">Full Name</label>
// //               <input
// //                 type="text"
// //                 className="form-control"
// //                 id="customer_name"
// //                 name="customer_name"
// //                 value={formData.customer_name}
// //                 onChange={handleChange}
// //                 required
// //               />
// //             </div>
// //           </div>
// //           <div className="col-md-6 my-2">
// //             <div className="form-group">
// //               <label htmlFor="mobile">Mobile</label>
// //               <input
// //                 type="text"
// //                 className="form-control"
// //                 id="mobile"
// //                 name="mobile"
// //                 value={formData.mobile}
// //                 onChange={handleChange}
// //                 required
// //               />
// //             </div>
// //           </div>
// //           <div className="col-md-6 my-2">
// //             <div className="form-group">
// //               <label htmlFor="customer_email">Email</label>
// //               <input
// //                 type="email"
// //                 className="form-control"
// //                 id="customer_email"
// //                 name="customer_email"
// //                 value={formData.customer_email}
// //                 onChange={handleChange}
// //                 required
// //               />
// //             </div>
// //           </div>
// //           <div className="col-md-6 my-2">
// //             <div className="form-group">
// //               <label htmlFor="customer_platformType">Platform</label>
// //               <br />
// //               <select
// //                 className="form-control"
// //                 id="customer_platformType"
// //                 name="customer_platformType"
// //                 value={formData.customer_platformType}
// //                 onChange={handleChange}
// //                 required
// //               >
// //                 <option value="" disabled>
// //                   Select Platform
// //                 </option>
// //                 <option value="AngelOne">Angel One</option>
// //                 <option value="Upstox">Upstox</option>
// //               </select>
// //             </div>
// //           </div>
// //           <div className="col-md-6 my-2">
// //             <div className="form-group">
// //               <label htmlFor="client_code">Client Code</label>
// //               <input
// //                 type="text"
// //                 className="form-control"
// //                 id="client_code"
// //                 name="client_code"
// //                 value={formData.client_code}
// //                 onChange={handleChange}
// //                 required
// //               />
// //             </div>
// //           </div>
// //           <div className="col-md-6 my-2">
// //             <div className="form-group">
// //               <label htmlFor="api_key">API Key</label>
// //               <input
// //                 type="text"
// //                 className="form-control"
// //                 id="api_key"
// //                 name="api_key"
// //                 value={formData.api_key}
// //                 onChange={handleChange}
// //                 required
// //               />
// //             </div>
// //           </div>
// //           <div className="col-md-6 my-2">
// //             <div className="form-group">
// //               <label htmlFor="registration_link">Registration Link</label>
// //               <input
// //                 type="text"
// //                 className="form-control"
// //                 id="registration_link"
// //                 name="registration_link"
// //                 value={formData.registration_link}
// //                 onChange={handleChange}
// //               />
// //             </div>
// //           </div>
// //         </div>
// //         <button type="submit" className="btn btn-primary">
// //           Submit
// //         </button>
// //       </form>
// //     </div>
// //   );
// // }

// import React, { useState, useEffect } from "react";
// import { useParams, useLocation } from "react-router-dom";
// import {
//   VERIFY_REGISTRATION_LINK,
//   CUSTOMER_REGISTRATION,
// } from "../Components/api";

// export default function CustomerForm() {
//   // const { id } = useParams();
//   // console.log(id)
//   const location = useLocation();
//   const [registerEmail, setRegisterEmail]= useState("")
//   console.log(registerEmail)
//   const [companyId, setCompanyId]= useState("")
//   const [registrationlink, setRegistrationlink] = useState("");
//   const [formData, setFormData] = useState({
//     customer_name: "",
//     mobile: "",
//     customer_email: "",
//     customer_platformType: "",
//     client_code: "",
//     api_key: "",
//     registration_link: "",
//   });
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null); // State to handle error message

//   useEffect(() => {
//     const searchParams = new URLSearchParams(location.search);
//     const token = searchParams.get("token");
//     setRegistrationlink(token)
//     console.log(token);
//     const fetchRegistrationLink = async () => {
//       // Remove token argument from here
//       if (token) {
//         try {
//           const response = await fetch(
//             `http://127.0.0.1:8000/api/verify-registration-link`,
//             {
//               method: "POST",
//               headers: {
//                 "Content-Type": "application/json",
//               },
//               body: JSON.stringify({ registration_link: token }), // Send token in request body
//             }
//           );
//           if (response.ok) {
//             const result = await response.json();
//             console.log(result);
//             setRegisterEmail(result.email)
//             setCompanyId(result.company_id)
//             setFormData((prevData) => ({
//               ...prevData,
//               registration_link: token,
//               customer_email: registerEmail,
//             }));
//             setLoading(false);
//           } else if (response.status === 400) {
//             // Handle 400 Bad Request
//             setError("This link is expired. Contact the Owner");

//             setLoading(false);
//           } else {
//             throw new Error("Failed to fetch registration link");
//           }
//         } catch (error) {
//           console.error("Error fetching registration link:", error);
//           setLoading(false);
//         }
//       } else {
//         console.error("Token not found in URL");
//         setLoading(false);
//       }
//     };

//     fetchRegistrationLink(); // Remove token argument from here
//   }, [location.search]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     // console.log(id)
//     e.preventDefault();
//     try {
//       const response = await fetch(
//         `http://127.0.0.1:8000/api/customer-registration/${companyId}`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(formData),
//         }
//       );
//       console.log(formData);
//       if (response.ok) {
//         const result = await response.json();
//         console.log("Success:", result);
//       } else {
//         console.error("Error:", response.statusText);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   if (loading) {
//     return <div className="loader">Loading...</div>; // Display a loader while fetching data
//   }

//   // Show error message if an error occurred
//   if (error) {
//     return <div className="error">
//       <div className="card text-center my-3">
//         <h5>{error}</h5>
//       </div>
//     </div>;
//   }

//   return (
//     <div className="container">
//       <form onSubmit={handleSubmit}>
//         <h2 className="text-center my-3">Registration Form</h2>
//         <div className="row">
//           <div className="col-md-6 my-2">
//             <div className="form-group">
//               <label htmlFor="customer_name">Full Name</label>
//               <input
//                 type="text"
//                 className="form-control"
//                 id="customer_name"
//                 name="customer_name"
//                 value={formData.customer_name}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//           </div>
//           <div className="col-md-6 my-2">
//             <div className="form-group">
//               <label htmlFor="mobile">Mobile</label>
//               <input
//                 type="text"
//                 className="form-control"
//                 id="mobile"
//                 name="mobile"
//                 value={formData.mobile}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//           </div>
//           <div className="col-md-6 my-2">
//             <div className="form-group">
//               <label htmlFor="customer_email">Email</label>
//               <input
//                 type="email"
//                 className="form-control"
//                 id="customer_email"
//                 name="customer_email"
//                 value={registerEmail}
//                 readOnly
//                 // onChange={handleChange}
//                 required
//               />
//             </div>
//           </div>
//           <div className="col-md-6 my-2">
//             <div className="form-group">
//               <label htmlFor="customer_platformType">Platform</label>
//               <br />
//               <select
//                 className="form-control"
//                 id="customer_platformType"
//                 name="customer_platformType"
//                 value={formData.customer_platformType}
//                 onChange={handleChange}
//                 required
//               >
//                 <option value="" disabled>
//                   Select Platform
//                 </option>
//                 <option value="AngelOne">Angel One</option>
//                 <option value="Upstox">Upstox</option>
//               </select>
//             </div>
//           </div>
//           <div className="col-md-6 my-2">
//             <div className="form-group">
//               <label htmlFor="client_code">Client Code</label>
//               <input
//                 type="text"
//                 className="form-control"
//                 id="client_code"
//                 name="client_code"
//                 value={formData.client_code}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//           </div>
//           <div className="col-md-6 my-2">
//             <div className="form-group">
//               <label htmlFor="api_key">API Key</label>
//               <input
//                 type="text"
//                 className="form-control"
//                 id="api_key"
//                 name="api_key"
//                 value={formData.api_key}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//           </div>
//           {/* <div className="col-md-6 my-2">
//             <div className="form-group">
//               <label htmlFor="registration_link">Registration Link</label>
//               <input
//                 type="text"
//                 className="form-control"
//                 id="registration_link"
//                 name="registration_link"
//                 value={registrationlink}
//                 onChange={handleChange}
//                 readOnly
//               />
//             </div>
//           </div> */}
//         </div>
//         <button type="submit" className="btn btn-primary">
//           Submit
//         </button>
//       </form>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  enforceMaxLength,
  enforceNoSpecialChars,
  enforceNoSpaces,
  enforceTextAndSpacesOnly,
} from "../Components/utility";
import {
  VERIFY_REGISTRATION_LINK,
  CUSTOMER_REGISTRATION,
} from "../Components/api";

export default function CustomerForm() {
  const location = useLocation();
  const [registerEmail, setRegisterEmail] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [subadminId, setsubadminid] = useState(null);
  console.log(subadminId);
  const [registrationlink, setRegistrationlink] = useState("");
  const [formData, setFormData] = useState({
    customer_name: "",
    mobile: "",
    customer_email: "",
    customer_platformType: "",
    client_code: "",
    api_key: "",
    registration_link: "",
    subadmin_id: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    setRegistrationlink(token);

    const fetchRegistrationLink = async () => {
      if (token) {
        try {
          const response = await fetch(VERIFY_REGISTRATION_LINK, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ registration_link: token }),
          });
          if (response.ok) {
            const result = await response.json();
            console.log(result);
            setRegisterEmail(result.email);
            setCompanyId(result.company_id);
            setsubadminid(result.subadmin_id);
            setLoading(false);
          } else if (response.status === 400) {
            setError("This link is expired. Contact the Owner");
            setLoading(false);
          } else {
            throw new Error("Failed to fetch registration link");
          }
        } catch (error) {
          console.error("Error fetching registration link:", error);
          setLoading(false);
        }
      } else {
        console.error("Token not found in URL");
        setLoading(false);
      }
    };

    fetchRegistrationLink();
  }, [location.search]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      customer_email: registerEmail,
      registration_link: registrationlink,
      subadmin_id: subadminId,
    }));
  }, [registerEmail, registrationlink]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    try {
      const response = await fetch(`${CUSTOMER_REGISTRATION}/${companyId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const result = await response.json();
        console.log("Success:", result);
        window.location.href = "/login";
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (loading) {
    return <div className="loader">Loading...</div>;
  }

  if (error) {
    return (
      <div className="error">
        <div className="card text-center my-3">
          <h5>{error}</h5>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <h2 className="text-center my-3">Registration Form</h2>
        <div className="row">
          <div className="col-md-6 my-2">
            <div className="form-group">
              <label htmlFor="customer_name">Full Name</label>
              <input
                type="text"
                className="form-control"
                id="customer_name"
                name="customer_name"
                value={formData.customer_name}
                onChange={handleChange}
                required
                onInput={enforceTextAndSpacesOnly}
              />
            </div>
          </div>
          <div className="col-md-6 my-2">
            <div className="form-group">
              <label htmlFor="mobile">Mobile</label>
              <input
                type="text"
                className="form-control"
                id="mobile"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                required
                onInput={enforceMaxLength}
              />
            </div>
          </div>
          <div className="col-md-6 my-2">
            <div className="form-group">
              <label htmlFor="customer_email">Email</label>
              <input
                type="email"
                className="form-control"
                id="customer_email"
                name="customer_email"
                value={formData.customer_email}
                readOnly
                required
              />
            </div>
          </div>
          <div className="col-md-6 my-2">
            <div className="form-group">
              <label htmlFor="customer_platformType">Platform</label>
              <br />
              <select
                className="form-control"
                id="customer_platformType"
                name="customer_platformType"
                value={formData.customer_platformType}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select Platform
                </option>
                <option value="AngelOne">Angel One</option>
                <option value="Upstox">Upstox</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 my-2">
            <div className="form-group">
              {/* <label htmlFor="client_code">Client Code</label> */}
              {formData.customer_platformType === "Upstox"
                ? "API Key"
                : "Client Code"}
              <input
                type="text"
                className="form-control"
                id="client_code"
                name="client_code"
                value={formData.client_code}
                onChange={handleChange}
                required
                onInput={enforceNoSpaces}
              />
            </div>
          </div>
          <div className="col-md-6 my-2">
            <div className="form-group">
              {/* <label htmlFor="api_key">API Key</label> */}
              {formData.customer_platformType === "Upstox"
                ? "API Secret"
                : "API Key"}
              <input
                type="text"
                className="form-control"
                id="api_key"
                name="api_key"
                value={formData.api_key}
                onChange={handleChange}
                required
                onInput={enforceNoSpaces}
              />
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  );
}
