import React, { useEffect } from "react";
import { CUSTOMER_LOGOUT } from "./api";
import { ToastContainer, toast } from "react-toastify";

export default function Navbar() {
  useEffect(() => {
    const checkTradeDate = () => {
      const tradeDate = localStorage.getItem("tradeDate");
      const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

      if (tradeDate !== currentDate) {
        localStorage.clear();
        window.location.href = "/login";
      }
    };

    checkTradeDate();
  }, []);

  const handleLogout = async () => {
    try {
      const customer_id = localStorage.getItem("customer_id"); // replace with actual customer_id

      const response = await fetch(CUSTOMER_LOGOUT, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customer_id }),
      });

      if (response.ok) {
        toast.success("Successfully logged out and now trade cannot be placed");
        localStorage.clear();
        window.location.href = "/login";
      } else {
        toast.error("Failed to log out. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="/dashboard">
          <img
            className="nav-logo"
            src="https://affyclouditsolutions.com/static/media/affy_logo.7354960bda008279c013.png"
            alt="logo icon"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-space-between"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {/* <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="#">
                Dashboard
              </a>
            </li> */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Account
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {/* <li>
                  <a className="dropdown-item" href="#">
                    Profile
                  </a>
                </li> */}
                <li>
                  <a className="dropdown-item" href="#" onClick={handleLogout}>
                    Logout
                  </a>
                </li>
              </ul>
              <ToastContainer />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
